<template>
  <div class="page">
    <div class="Box flex-col align-center">
      <header class="top-Img">
        <div class="title"></div>
      </header>
      <span class="top-txt one-txt"
        >首次投放可享受1元特价！最多可投60秒！</span
      >
      <span class="top-txt"
        >首次投放系统会自动帮您选用此优惠。</span
      >
      <span class="top-txt"
        >小提醒：如果您的视频不足60秒，可多次投放用足60秒哦！如：视频只有15秒，“广告投放总次数”填4次，您也仅需支付1元。</span
      >
      <div class="titleTop" style="margin-top: 24rem" ref="scrollDiv1">
        <span class="leftIcon"></span>
        <span class="titleText">花1元为自己打Call</span>
      </div>
      <div class="video-box">
        <video
          poster="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/img/discountsA.png"
          src="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/video/discountsVideoA.mp4"
          controls
          class="video"
        ></video>
      </div>
      <div class="titleTop" ref="scrollDiv1">
        <span class="leftIcon"></span>
        <span class="titleText">花1元为喜欢的人打Call</span>
      </div>
      <div class="video-box">
        <video
        poster="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/img/discountsB.png"
          src="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/video/discountsVideoB.mp4"
          controls
          class="video"
        ></video>
      </div>
      <div class="titleTop" ref="scrollDiv1">
        <span class="leftIcon"></span>
        <span class="titleText">花1元为自己的品牌打Call</span>
      </div>
      <div class="video-box">
        <video
          poster="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/video/discountsC_0313.png"
          src="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/video/discountsVideoC_0313.mp4"
          controls
          class="video"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      // 获取所有视频元素
      const videos = Array.from(document.getElementsByTagName("video"));
      // 为每个视频添加事件监听器
      videos.forEach((video) => {
        // 判断是否Safari浏览器
        if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) {
          video.style = 'object-fit: cover';
        } else {
          video.style = 'object-fit: fill';
        }
        video.addEventListener('play', (event) => {
        // 暂停其他所有视频
          videos.forEach((otherVideo) => {
            if (otherVideo !== event.target) {
              otherVideo.pause();
            }
          });
        });
      });
    });
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.page {
  min-height: calc(100vh - 233rem);
  padding-top: 80rem;
  padding-bottom: 100rem;

  .Box {
    width: 1280rem;
    margin: 0 auto;
    background: #ffffff;
    padding-bottom: 80rem;
    position: relative;
    .top-Img {
      width: 100%;
      height: 231rem;
      background: url("../../assets/img/launch-top-back.png");
      background-size: 100% 100%;

      .title {
        width: 267rem;
        height: 65rem;
        background: url("../../assets/img/newPeopleDiscountsLogo.png");
        background-size: 100% 100%;
        margin: 38rem auto 0;
      }
    }
    .top-txt {
      display: inline-block;
      width: 876rem;
      font-size: 18rem;
      letter-spacing: 2rem;
      color: #2d2d2d;
      line-height: 25rem;
      margin-top: 24rem;
    }

    .one-txt {
      margin-top: 0;
      position: absolute;
      top: 197rem;
      left: 202rem;
    }

    .titleTop {
      display: flex;
      align-items: center;
      margin-top: 50rem;
      margin-bottom: 35rem;
      .leftIcon {
        display: inline-block;
        width: 3rem;
        height: 18rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }

      .titleText {
        width: 876rem;
        font-size: 20rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2D2D2D;
        line-height: 28rem;
        position: relative;
        left: 17rem;
      }
    }
    .video-box {
      width: 728rem;
      height: 410rem;

      .video {
        width: 100%;
        height: 100%;
        border-radius: 15rem;
      }
    }
  }
}
</style>
